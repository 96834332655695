@import '~antd/dist/antd.css';
@import '~video-react/dist/video-react.css';

.KnifeExample {
  margin:auto;
  width: 1600px;
  height: 900px;
  text-align: center;
  background-image:url(./img/background4.png) ;
  background-size:100% 100%;
}

.KnifeExample-header {
  display: flex;
  padding-top: 100px;
  flex-direction: column;
  align-items: center;
}

.returnBtn {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
