@import '~antd/dist/antd.css';
@import '~video-react/dist/video-react.css';

.KnifeExample1 {
  margin:auto;
  width: 1600px;
  height: 900px;
  text-align: center;
  background-image:url(./img/background5.png) ;
  background-size:100% 100%;
}

.Example1-header {
  display: flex;
  padding-top: 50px;
  padding-right:200px;
  flex-direction: column;
  align-items: center;
}

.section1 {
  margin-top: 50px;
  height: 850px;
  font-size: 24px;
  width: 450px;
  margin-left: 0px;
  background-size:100% 100%;
  background-image:url(./img/section11.png) ;
  text-align: start;
  font-family: "Microsoft YaHei", serif;
}

.text1 {
  margin: 50px;
  text-indent:2em;
  text-align: start;
  line-height: 200%;
  font-size: 20px;
  white-space: pre-wrap;
  padding: 80px 20px;
  text-align-all: center;
  font-family: "Microsoft YaHei", serif;
}

.KnifeExample1-section2 {
  margin-top: 80px;
  height: 750px;
  width: 950px;
  margin-left: -10px;
  background-size:100% 100%;
  background-image:url(./img/section21.png) ;
}

.returnBtn {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
