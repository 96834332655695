@import '~antd/dist/antd.css';

.App-login {
  margin:auto;
  width: 1600px;
  height: 900px;
  background-image:url(./img/main.jpg) ;
  background-size:100% 100%;
}

.App-header {
  padding-top: 400px;
  padding-left: 100px;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.usage {
  margin: 40px;
  align-items: center;
}

.usage1 {
  align-items: center;
}
