@import '~antd/dist/antd.css';
@import '~video-react/dist/video-react.css';

.KnifeAfter {
    margin: auto;
    width: 1600px;
    height: 900px;
    text-align: center;
    background-image: url(./img/background5.png);
    background-size: 100% 100%;
}

.KnifeAfter-header {
    margin-top: 70px;
    text-align: start;
    padding-left: 10px;
    z-index: 0;
}

.KnifeAfter-section1 {
    height: 77%;
    width: 100%;
    margin-top: -5px;
    z-index: 6;
    text-align: start;
    background-size: 100% 100%;
    background-image: url(./img/after_background.png);
}


.KnifeAfter-text1 {
    margin: 50px 50px 0px;
    font-size: large;
    text-align: start;
    width: 600px;
    font-family: "Microsoft YaHei", serif;
}


.returnBtn {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

@keyframes App-logo-spin {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}
