@import '~antd/dist/antd.css';
@import '~video-react/dist/video-react.css';

.KnifeAnswer {
    margin: auto;
    width: 1600px;
    height: 900px;
    background-image: url(./img/background5.png);
    background-size: 100% 100%;
}

.KnifeAnswer-section1 {
    position: absolute;
    height: 920px;
    width: 1600px;
    margin-top: 150px;
    background-size: 100% 100%;
    background-image: url(./img/after_background.png);
}


.KnifeAnswer-text1 {
    font-size: large;
    overflow-y: scroll;
    margin-top: 50px;
    padding: 50px 50px 50px 50px;
    text-align: start;
    line-height: 200%;
    white-space: pre-wrap;
    width: 95%;
    height: 80%;
    font-family: "Microsoft YaHei", serif;
}


.returnBtn {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

@keyframes App-logo-spin {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}
