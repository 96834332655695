@import '~antd/dist/antd.css';
@import '~video-react/dist/video-react.css';

.KnifeScore {
    position: absolute;
    width: 1600px;
    height: 900px;
    text-align: center;
    background-image: url(./img/background5.png);
    background-size: 100% 100%;
}

.KnifeScore-section {
    position: absolute;
    margin-top: 160px;
    margin-left: 100px;
    height: 77%;
    width: 90%;
    text-align: start;
    background-size: 100% 100%;
    background-image: url(./img/score.png);
}

.KnifeScore-text1 {
    position: absolute;
    margin-top: 230px;
    margin-left: 230px;
    font-size: xx-large;
    text-align: start;
    font-family: "Microsoft YaHei", serif;
}

.KnifeScore-text2 {
    position: absolute;
    margin-top: 390px;
    margin-left: 230px;
    font-size: xx-large;
    text-align: start;
    font-family: "Microsoft YaHei", serif;
}

.KnifeScore-text3 {
    position: absolute;
    margin-top: 580px;
    margin-left: 230px;
    font-size: xx-large;
    text-align: start;
    font-family: "Microsoft YaHei", serif;
}

.KnifeScore-text4 {
    position: absolute;
    margin-top: 600px;
    margin-left: 1290px;
    font-size: 60px;
    font-weight: bold;
    text-underline: #2f54eb;
    text-align: start;
    font-family: "Microsoft YaHei", serif;
}


@keyframes App-logo-spin {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}
