@import '~antd/dist/antd.css';

.Knife {
  margin:auto;
  width: 1600px;
  height: 900px;
  text-align: center;
  background-image:url(./img/background2.jpg) ;
  background-size:100% 100%;
}

.Knife-header {
  display: flex;
  padding-top: 200px;
  flex-direction: column;
  align-items: center;
}

.Knife-Model {
  padding-top: 200px;
}

.Knife-Text {
  padding-top: 200px;
  font-size: 30px;
  text-align: start;
  font-family: "Microsoft YaHei", serif;
}

.Knife-Tip {
  margin-top: 300px;
  font-size: 18px;
  text-align: start;
  font-family: "Microsoft YaHei", serif;
}

.returnBtn {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
