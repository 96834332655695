@import '~antd/dist/antd.css';

.App {
  margin:auto;
  width: 1600px;
  height: 900px;
  background-image:url(./img/main.jpg) ;
  background-size:100% 100%;
}

.App-header {
  padding-top: 400px;
  padding-left: 100px;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.menu{
  padding-top: 250px;
  width: 300px;
  padding-left: 700px;
}
.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
