@import '~antd/dist/antd.css';
@import '~video-react/dist/video-react.css';

.KnifeUsage {
    margin: auto;
    width: 1600px;
    height: 900px;
    text-align: center;
    background-image: url(./img/background3.png);
    background-size: 100% 100%;
}

.Usage-header {
    display: flex;
    margin-top: 80px;
    flex-direction: column;
    align-items: center;
}

.Usage {
    width: 1300px;
    height: 700px;
    margin-top: 100px;
    background-image: url(./img/usage1_intro.png);
    background-size: 100% 100%;
}

.Usage-Text {
    padding-top: 100px;
    font-size: 30px;
    padding-left: 20px;
    text-indent: 2em;
    text-align: start;
    padding-right: 20px;
    font-family: "Microsoft YaHei", serif;
}

.Usage-Image {
    position: absolute;
    height: 400px;
    bottom: 20px;
    left: 100px;
}

.Usage-Video {
    position: absolute;
    width: 500px;
    bottom: 100px;
    left: 750px;
}

.returnBtn {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

@keyframes App-logo-spin {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}
